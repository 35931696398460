<template>
  <em>The {{ type }} widget has no properties</em>
</template>

<script>
import WidgetPropertiesMixin from '../../../mixins/WidgetPropertiesMixin';

export default {
  name: 'NullProperties',
  mixins: [WidgetPropertiesMixin],

};
</script>

<style>

</style>
